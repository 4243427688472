import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import WhiteLogo from '../../assets/images/beevro_white.png';
import SubjectHeader from '../SubjectHeader';

const Footer = (props) => {
    return (
        <div className="bg-black mt-10 text-white">

            <div className="md:flex justify-between mx-5 py-8">
                <div className="md:w-3/12">
                    <img className="h-9" src={WhiteLogo} alt="beevro" />
                    <div className="mt-5">Dijital Dönüşüm Ajansı olarak, yaratıcı ve dönüşüm odaklı stratejilerle işinizi yeni seviyelere taşıyoruz. 360 derece hizmetlerimizle dijital dünyada başarınızı garantiliyoruz.</div>
                </div>
                <div className="my-5">
                    <div class="text-white">KURUMSAL</div>
                    <div class="h-1 w-4 mr-3 rounded bg-bee-1 mb-3"></div>
                    <ul>
                        <li>Hakkımızda</li>
                        <li>Çerez Politikası</li>
                        <li>Müşteri Politikası</li>
                        <li>Projeler</li>
                    </ul>
                </div>
                <div className="my-5">
                    <div class="text-white">HİZMETLERİMİZ</div>
                    <div class="h-1 w-4 mr-3 rounded bg-bee-1 mb-3"></div>
                    <ul>
                        <li>Web Sitesi Tasarım</li>
                        <li>Mobil Uygulama</li>
                        <li>Seo</li>
                        <li>Sosyal Medya</li>
                        <li>Grafik</li>
                    </ul>
                </div>
                <div className="my-5">
                    <div class="text-white">İLETİŞİM</div>
                    <div class="h-1 w-4 mr-3 rounded bg-bee-1 mb-3"></div>
                    <div>0553 569 72 18</div>
                    <div>hi@beevro.com</div>
                    {/* google meet linki oluşturulacak */}
                    {/* bigfill den örnek alınacak */}
                </div>
            </div>
            <div className="text-center bg-bee-1 p-4 text-black">
                © All rights Reservel Copyright 2023
            </div>
        </div>
    );
}

export default Footer;