import React from 'react';
import SubjectHeader from '../SubjectHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faTwitter, faInstagram, faTiktok } from '@fortawesome/free-brands-svg-icons'
import Instagram1 from '../../assets/images/instagram/instagram-1.jpg'
import Instagram2 from '../../assets/images/instagram/instagram2.jpg'
import Instagram3 from '../../assets/images/instagram/instagram3.jpg'
import { motion } from 'framer-motion';

const OnInstagram = (props) => {

    return (
        <>
            <SubjectHeader title="Sosyal Medyada Biz" />
            {/* <div className="md:flex justify-around">
                <motion.img
                    initial={{ opacity: 0, scale: 0.2 }}
                    whileInView={{ opacity: 1, scale: 1 }}
                    transition={{ delay: 0.3 }}
                    viewport={{ once: true }}
                    className="rounded-3xl h-60 m-auto my-6" src={Instagram2} alt="instagram1" />
                <motion.img
                    initial={{ opacity: 0, scale: 0.2 }}
                    whileInView={{ opacity: 1, scale: 1 }}
                    transition={{ delay: 0.3 }}
                    viewport={{ once: true }} className="rounded-3xl h-60 m-auto my-6" src={Instagram2} alt="instagram2" />
                <motion.img
                    initial={{ opacity: 0, scale: 0.2 }}
                    whileInView={{ opacity: 1, scale: 1 }}
                    transition={{ delay: 0.3 }}
                    viewport={{ once: true }} className="rounded-3xl h-60 m-auto my-6" src={Instagram3} alt="instagram3" />
            </div> */}
            <div className="flex justify-evenly my-12">
                <motion.button
                    initial={{ opacity: 0, scale: 0.2 }}
                    whileInView={{ opacity: 1, scale: 1 }}
                    transition={{ delay: 0.3 }}
                    viewport={{ once: true }}
                >
                    <FontAwesomeIcon size='3x' color='#fff' icon={faFacebookF} />
                </motion.button>
                <motion.button
                    initial={{ opacity: 0, scale: 0.2 }}
                    whileInView={{ opacity: 1, scale: 1 }}
                    transition={{ delay: 0.3 }}
                    viewport={{ once: true }}>
                    <FontAwesomeIcon size='3x' color='#fff' icon={faTwitter} />
                </motion.button>
                <motion.button
                    initial={{ opacity: 0, scale: 0.2 }}
                    whileInView={{ opacity: 1, scale: 1 }}
                    transition={{ delay: 0.3 }}
                    viewport={{ once: true }}>
                    <FontAwesomeIcon size='3x' color='#fff' icon={faInstagram} />
                </motion.button>
                <motion.button
                    initial={{ opacity: 0, scale: 0.2 }}
                    whileInView={{ opacity: 1, scale: 1 }}
                    transition={{ delay: 0.3 }}
                    viewport={{ once: true }}>
                    <FontAwesomeIcon size='3x' color='#fff' icon={faTiktok} />
                </motion.button>
            </div>
        </>

    );
}

export default OnInstagram;