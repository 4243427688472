import React from 'react';
import LastProjectsContentComp from './LastProjectsContentComp';
import SubjectHeader from '../SubjectHeader';
import { Swiper, SwiperSlide } from "swiper/react";
import './LastProject.scss'

import Project1 from '../../assets/images/ourReferences/logo1.png';
import Project2 from '../../assets/images/ourReferences/logo4.png';
import Project3 from '../../assets/images/ourReferences/logo7.png';
import { motion } from 'framer-motion';

const LastProjects = (props) => {
    const lastProjects = [
        { image: Project1, brand: 'Matrix Medya', workSubject: 'Web Sitesi', url: "https://www.matrixmedya.com/" },
        { image: Project2, brand: 'Sözleşmeli Emlak', workSubject: 'Web Sitesi', url: "https://sozlesmeliemlak.com.tr/" },
        { image: Project3, brand: 'Avrupa Yardım Vakfı', workSubject: 'Video', url: "https://www.youtube.com/@AvrupaYardimVakfi/videos" },
        { image: Project3, brand: 'Avrupa Yardım Vakfı', workSubject: 'Video', url: "https://www.youtube.com/@AvrupaYardimVakfi/videos" },
    ];
    return (
        <div style={{ backgroundColor: 'rgb(0 0 0 / 80%)' }} className='md:py-12 py-3 md:my-3 '>
            <div className="flex justify-around md:mb-12">
                <motion.div
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    className="">
                    <div className="flex items-center">
                        <div className="h-1 w-14 mr-3 rounded bg-bee-1"></div>
                        <div className="text-4xl text-white">İşler.</div>
                    </div>
                    <div className="text-bee-1 mt-2">YAPTIĞIMIZ ŞEYLER</div>
                </motion.div>
                <button>Hepsini Gör</button>
            </div>
            <div className="hex-container md:flex justify-around">
                {lastProjects.map((val, index) => {
                    return (
                        <motion.div
                            onClick={() => window.open(val.url, '_blank')}
                            initial={{ opacity: 0, scale: 0.2 }}
                            whileInView={{ opacity: 1, scale: 1 }}
                            transition={{ delay: 0.3 }}
                            viewport={{ once: true }}
                            className="hex relative">
                            {/* <div className="hex" style={{ background: index % 2 === 1 ? 'rgb(150 150 150 / 50%)' : 'rgb(255 255 255 / 50%)' }}> */}
                            <div className="absolute z-10 top-0 bottom-0 flex justify-around items-center flex-col">
                                <img height="250" width="250" src={val.image} />
                                <div className="text-center">
                                    <div className="last-project-text text-white">{val.brand}</div>
                                    <div className="last-project-text text-white">{val.workSubject}</div>
                                </div>
                            </div>
                        </motion.div>
                    )
                })}

            </div>
        </div>
    );
};

export default LastProjects;
