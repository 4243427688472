
import '../App.css';
import Menu from '../component/Menu';
import Slider from '../component/Slider/Slider';
// import WeDoing from '../component/Text/SubjectHeaderTitle';
import WeDoing from '../component/WeDoing/WeDoing';
import LastProjects from '../component/LastProjects';
import LogoCarousel from '../component/LogoCarousel/LogoCarousel';
import LeaveMessage from '../component/LeaveMessage/LeaveMessage';
import OurNews from '../component/OurNews/OurNews';
import OnInstagram from '../component/OnInstagram/OnInstagram';
import Footer from '../component/Footer/Footer';
import CustomerComment from '../component/CustomerComments/CustomerComment';

const HomePage = () => {
    return (
        <>
            {/* <div className="yellowShadow"></div> */}
            <Slider />
            {/* <WeDoing /> */}
            {/* 
      */}
            <WeDoing />


            <LastProjects />

            {/* <LogoCarousel /> */}
            {/* <CustomerComment /> */}
            
            <OnInstagram />
            <LeaveMessage />
            {/* burada silinip yazılan mesajlara google anahtar kelimeler yazılabilir */}
            {/* <OurNews /> */}
            {/* Müşteri yorumları */}
            {/* ürünlerimiz olacak - restoran qr kodu oluşturma menü güncelleme */}
            {/* projeni anlat kısmında bir paket yapacağız. içerisinde sosyal medya yönetimi, logo, web sitesi bu tarz şeyler olacak*/}
            {/* bütün veriler girdikten sonra sizinle 1 dakika içerisinde whatsapp'dan iletişime geçeceğiz yazıp bütün gelen verileri mesaj olarak göndereğiz */}
            {/* sohbet başlat sağ altta fixed olacak. instagram, facebook, whatsapp, online toplantı butonları olacak */}
        </>

    );
}

export default HomePage;
