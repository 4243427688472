import React from 'react';
import BeeFly from '../BeeFly/BeeFly';
import Typewriter from '../Text/TypeWriter2';
import { motion } from "framer-motion"

const Slider = (props) => {
    return (
        <motion.div
            initial={{ translateY:-100, opacity:0 }}
            whileInView={{ translateY: 0, opacity:1 }}
            viewport={{ once: true }}
            className="flex md:flex-row flex-col justify-around items-center h-96">
            <div className="text-white z-10">
                <h1 className="md:text-5xl">DİJİTALDE ARI GİBİ:</h1>
                <Typewriter className="md:text-3xl" data={["Yenilikçi",
                    "Hızlı",
                    "Başarılı",
                    "Dinamik",
                    "Yaratıcı",
                    "Özgün",
                    "Esnek",
                    "Çözüm Odaklı"]} />
            </div>
            <BeeFly />
        </motion.div>
    );
}

export default Slider;