import React from 'react';
import "./button.css";

const Button = ({title}) => {
    return (
        <div class="buttons">
            <button class="button1 bee-button">{title}<span></span><span></span></button>
        </div>
    );
}

export default Button;