import React from 'react';

const SubjectHeader = ({ title, color = "white" }) => {
    return (
        <div style={{color:color}} className="flex flex-col items-center my-3">
            <b className="text-2xl">{title}</b>
            <div style={{ width: "100px" }} className="w-100 bg-bee-1 h-1 rounded"></div>
        </div>
    );
}

export default SubjectHeader;