import React, { useState } from 'react';
import Ari from '../../assets/images/ari.png'
import SagKanat from '../../assets/images/sagKanat.png'
import SolKanat from '../../assets/images/solKanat.png'
import './beefly.css'
import { motion } from "framer-motion"

const BeeFly = () => {
  return (
    <motion.div
      drag
      dragConstraints={{
        top: -50,
        left: -50,
        right: 50,
        bottom: 50,
      }}
      initial={{ opacity: 0, }}
      whileInView={{ opacity: 1, }}
      viewport={{ once: true }}
    >
      <div
        className="arilar">
        <img src={Ari} alt="Arı Resmi" />
        <div className="sag-kanat">
          <img src={SagKanat} alt="Sağ Kanat" />
        </div>
        <div className="sol-kanat">
          <img src={SolKanat} alt="Sol Kanat" />
        </div>
      </div>
    </motion.div>
  );
};

export default BeeFly;
