import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/Homepage';
import Menu from './component/Menu';
import Footer from './component/Footer/Footer';
import Project from './pages/Project';
import AboutUs from './pages/AboutUs';
import FixedContact from './component/FixedContact/FixedContact';

const App = () => {
  return (
    <div className="app">
      <Router>
        <Menu />
        {/* <FixedContact/> */}
        <Routes>
          <Route path="/" element={<HomePage/>} />
          {/* <Route path="/hakkimizda" element={<AboutUs/>} /> */}
          {/* <Route exact path="/projeniAnlat" element={<Project/>} /> */}
        </Routes>
        <Footer />
      </Router>
    </div>
  );
};

export default App;