import React, { useEffect } from 'react';
import Particles from "react-tsparticles";
import { loadFull } from 'tsparticles';


import Button from '../Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import Typewriter from '../Text/TypeWriter2';
import { motion } from 'framer-motion';

const LeaveMessage = (props) => {
    const particlesInit = async (main) => {
        await loadFull(main);
    };
    return (
        <motion.div
            initial={{ opacity: 0, scale: 0.2 }}
            whileInView={{ opacity: 1, scale: 1 }}
            transition={{ delay: 0.3 }}
            viewport={{ once: true }}
            style={{ backgroundColor: 'rgb(0 0 0 / 80%)' }} className="md:flex m-auto justify-around md:py-12 md:mb-12 md:my-3 my-10">
            <div className="md:w-6/12 w-full h-full text-white text-3xl text-center">
                <div className="md:text-xl text-lg">Bir sorunuz mu var ?</div>
                <Typewriter className="md:text-xl text-xs" data={["Web Tasarım", "Sosyal Medya Yönetimi", "Grafik Tasarım", "Seo", "Mobil Uygulama"]} />
            </div>
            <div className="md:w-2/12 h-full m-auto md:m-0 my-5 mx-10">
                <button className="whatsapp-button w-full">
                    {/* <FontAwesomeIcon size="xl" color='#075E54' icon={faWhatsapp} /> */}
                    <div className="md:text-xl text-lg">Konuşalım.</div>
                    <div className="whatsapp-online-dot"></div>
                </button>
            </div>

        </motion.div>
    );
}

export default LeaveMessage;