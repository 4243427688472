import React, { useState, useEffect } from 'react';
import WhiteLogo from '../assets/images/beevro_white.png';
import BlackLogo from '../assets/images/beevro_black.png';
import Button from './Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons'
import { Events, scrollSpy } from 'react-scroll';
import { motion } from 'framer-motion';

const Menu = () => {
    const [activeItem, setActiveItem] = useState('Anasayfa');
    const [isMenuOpen, setMenuOpen] = useState(false);
    const [stickyClass, setStickyClass] = useState();

    const menuItems = [
        { title: 'Anasayfa', route: '/' },
        { title: 'Hakkımızda', route: '/' },
        { title: 'Neler Yapıyoruz?', route: '/' },
        // { title: 'Ürünlerimiz', route: '/' },
        // { title: 'Blog', route: '/' },
        // { title: 'İletişim', route: '/' },
    ];

    const handleItemClick = (title) => {
        setActiveItem(title);
        setMenuOpen(false); // Menü öğesine tıklandığında menünün kapanmasını sağlar
    };

    const handleMenuToggle = () => {
        setMenuOpen(!isMenuOpen); // Menü butonuna tıklandığında menünün açılıp kapanmasını sağlar
    };

    useEffect(() => {
        window.addEventListener('scroll', stickNavbar);

        return () => {
            window.removeEventListener('scroll', stickNavbar);
        };
    }, []);

    const stickNavbar = () => {
        if (window !== undefined) {
            let windowHeight = window.scrollY;
            console.log(windowHeight)
            if(windowHeight > 150){
                setStickyClass('bg-white bg-opacity-75 backdrop-filter fixed top-0 left-0 right-0 z-50')
            }else{
                setStickyClass('')
            }
            //   windowHeight > 500 ? setStickyClass('fixed top-0 left-0 z-50') : setStickyClass('relative');
        }
    };

    return (
        <div className={`menu ${stickyClass} transition duration-500`}>
            <div className="md:m-5 m-3">
                <div className="flex justify-between items-center">
                    <div>
                        <img className="md:h-9 h-5" src={stickyClass ? BlackLogo : WhiteLogo} alt="beevro" />
                    </div>
                    <div className="flex items-center">
                        <div className="md:hidden">
                            {/* Menü Butonu */}
                            <button
                                onClick={handleMenuToggle}
                                className="text-white p-2 rounded-md focus:outline-none"
                            >
                                <FontAwesomeIcon size='lg' color={stickyClass ? '#000' : '#fff'} icon={faBars} />
                            </button>
                        </div>
                        {/* Menü */}
                        <div className="hidden md:flex">
                            <ul className={`flex ${stickyClass ? 'text-black' : 'text-white'}`}>
                                {menuItems.map((val) => {
                                    const isActive = val.title === activeItem;
                                    return (
                                        <a href="#" key={val.title}>
                                            <li
                                                className={`mr-3 p-2 rounded-full ${isActive
                                                    ? 'bg-white text-black transition-colors duration-500'
                                                    : ''
                                                    }`}
                                                onClick={() => handleItemClick(val.title)}
                                            >
                                                {val.title}
                                            </li>
                                        </a>
                                    );
                                })}
                            </ul>
                            
                    <Button title="Projeni Anlat" />
                        </div>
                    </div>
                </div>
                {/* Menü Overlay */}
                {isMenuOpen && (
                    <motion.div
                    initial={{ opacity: 0, scale: 0.2 }}
                    whileInView={{ opacity: 1, scale: 1 }}
                    viewport={{ once: true }} 
                    className={`fixed top-0 left-0 right-0 bottom-0 z-50 bg-black bg-opacity-75 backdrop-filter backdrop-blur-sm justify-center items-center text-center flex ${isMenuOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-500`}>
                        <button
                            onClick={handleMenuToggle}
                            className="text-white p-2 rounded-md focus:outline-none absolute right-0 top-0"
                        >
                            <FontAwesomeIcon size='lg' color='#fff' icon={faXmark} />
                        </button>
                        <div className="container mx-auto px-4 py-2">
                            {/* Menü İçeriği */}
                            <ul className="flex flex-col text-white">
                                {menuItems.map((val) => {
                                    const isActive = val.title === activeItem;
                                    return (
                                        <a href="#" key={val.title}>
                                            <li
                                                className={`py-2 rounded-md ${isActive
                                                    ? 'bg-white text-black transition-colors duration-500'
                                                    : ''
                                                    }`}
                                                onClick={() => handleItemClick(val.title)}
                                            >
                                                {val.title}
                                            </li>
                                        </a>
                                    );
                                })}
                            </ul>
                            
                    <Button title="Projeni Anlat" />
                        </div>
                    </motion.div>
                )}
            </div>
        </div>
    );
};

export default Menu;
