import React from 'react';

function AboutUs(props) {
    return (
        <div>
            <div>
                <div>
                    Antalya Dijital Ajansı olarak, dijital dünyada varlık göstermek ve işletmelerin online hedeflerine ulaşmasına yardımcı olmak için birlikte çalışıyoruz.
                    Web tasarımı, SEO, sosyal medya yönetimi, grafik tasarımı ve mobil uygulama gibi çeşitli hizmetler sunmaktayız. Web tasarımı, online varlığınızın temelini oluşturur.
                    İşletmenizin ihtiyaçlarına ve hedeflerine uygun modern, kullanıcı dostu ve estetik açıdan çekici web siteleri tasarlıyoruz.
                </div>
                <br />
                <div>
                    Kullanıcı deneyimini önceliğimize alırken, arama motoru optimizasyonu (SEO) konusunda da uzmanız. Sitenizin Google ve diğer arama motorlarında üst sıralarda yer almasını sağlamak için doğru stratejileri uyguluyoruz.

                    Sosyal medya yönetimi, işletmenizin sosyal medya platformlarında etkin bir varlık sağlamasına yardımcı olur.
                    Markanızı güçlendirmek, hedef kitleye ulaşmak ve etkileşimi artırmak için özgün içerikler oluşturuyor, stratejik olarak yayınlıyor ve takipçi sayısını artırıyoruz.                Grafik tasarım hizmetlerimizle işletmenizin görsel kimliğini oluşturuyoruz. Logolar, broşürler, afişler, reklam materyalleri ve daha fazlası için yaratıcı ve etkileyici tasarımlar sunuyoruz. Markanızı yansıtan, tanınabilir ve unutulmaz grafiklerle müşterilerinizi etkilemeyi hedefliyoruz.                Ayrıca mobil uygulama geliştirme konusunda da uzmanız. İşletmenizin ihtiyaçlarına uygun, kullanıcı dostu ve işlevsel mobil uygulamalar tasarlıyoruz. Mobil cihazlar üzerinden kullanıcılarınıza daha iyi bir deneyim sunmanıza yardımcı oluyoruz.                Ekip olarak, her bir projeye yaratıcı bir yaklaşım benimsiyoruz ve müşterilerimizin ihtiyaçlarına özel çözümler sunuyoruz. İşletmenizin büyümesine katkıda bulunmak, online varlığınızı güçlendirmek ve rekabette öne çıkmak için profesyonel bir yaklaşım sunuyoruz.                Sizinle çalışmayı ve dijital hedeflerinizi gerçeğe dönüştürmeyi sabırsızlıkla bekliyoruz. Antalya Dijital Ajansı olarak, işletmenize değer katmak için buradayız.
                </div>
            </div>
            <div>
                <img src="" />
            </div>
        </div>
    );
}

export default AboutUs;