import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination"
import "swiper/css/effect-coverflow"
import SwiperCore, { Pagination, EffectCoverflow } from 'swiper';
import './WeDoing.scss'
import SubjectHeader from "../SubjectHeader";
import Lottie from 'react-lottie';
import Web from '../../assets/images/wedoing/web.json'
import Mobile from '../../assets/images/wedoing/mobileApp.json'
import Seo from '../../assets/images/wedoing/seo.json'
import Graphic from '../../assets/images/wedoing/graphicDesign.json'
import Social from '../../assets/images/wedoing/socialmedia.json'
import { motion, useAnimation } from "framer-motion"
import {
  Tab,
  initTE,
} from "tw-elements";
// Swiper modules
SwiperCore.use([Pagination, EffectCoverflow]);

const WeDoing = () => {
  initTE({ Tab });
  const data = [
    {
      id: 0, title: "Sosyal Medya Yönetimi",
      animation: Social, content: "Markanızın güçlü bir dijital varlık olmasını sağlamak için buradayız! \n\n Sosyal medya yönetimi konusundaki uzmanlığımızla, hedef kitlenize etkileyici bir şekilde ulaşmanızı sağlıyoruz.\n\n Stratejik içerik oluşturma, trendleri takip etme ve analizlerle sürekli gelişme sağlama konusunda size destek oluyoruz. Sosyal medyadaki varlığınızı büyütmek için bize güvenin!"
    },
    {
      id: 1, title: "Mobil Uygulama",
      animation: Mobile, content: "Markanızı her an yanlarında taşımak isteyen kullanıcılara özel mobil uygulamalar sunuyoruz. \n\n iOS ve Android platformlarında etkileyici kullanıcı deneyimleri sağlamak için buradayız. Uygulama geliştirme konusundaki uzmanlığımızla, markanızı mobil dünyada öne çıkarıyoruz. \n\n Kullanıcı dostu arayüzler ve güçlü özelliklerle donatılmış mobil uygulamalarımız, hedef kitlenizin beklentilerini aşacak bir deneyim sunmanızı sağlar."
    },
    {
      id: 2, title: "Web Tasarım",
      animation: Web, content: "Göz Alıcı Web Tasarımlarıyla İnternetin Gücünü Keşfedin! \n\n Dijital varlığınızı etkileyici bir şekilde temsil etmek ve çevrimiçi varlığınızı güçlendirmek için buradayız! Profesyonel web tasarım hizmetimizle size özel çözümler sunuyoruz. \n\n Kullanıcı dostu arayüzlerle donatılmış göz alıcı web siteleriyle markanızın çevrimiçi varlığını güçlendiriyoruz. Estetik tasarımlarımızla, markanızın kişiliğini en iyi şekilde yansıtıyor ve ziyaretçilerinizi etkiliyoruz."
    },

    {
      id: 3, title: "Seo",
      animation: Seo, content: "Web Sitenizi Arama Motorlarında Öne Çıkarın! \n\n Çevrimiçi görünürlüğünüzü artırmak ve hedef kitlenizin sizi kolayca bulmasını sağlamak için buradayız! SEO (Arama Motoru Optimizasyonu) çalışmalarımızla web sitenizi arama motorlarında öne çıkarıyoruz. \n\n Analizler yaparak sitenizi optimize ediyor ve arama motorlarının sizi daha iyi anlamasını sağlıyoruz."
    },

    {
      id: 4, title: "Grafik Tasarım",
      animation: Graphic, content: "Görsel İletişimde Sizi Öne Çıkarıyoruz! \n\n Markanızın kişiliğini yansıtan görseller oluşturarak sizin için benzersiz bir marka kimliği oluşturuyor. Logo tasarımı, marka kimlikleri, broşürler, afişler, dijital grafikler ve daha fazlasını kapsayan geniş bir yelpazede hizmet sunuyoruz. \n\n Yaratıcı fikirlerimizle markanızı öne çıkarırken, profesyonel tasarımlarımızla iz bırakmanızı sağlıyoruz. İhtiyaçlarınızı anlamak ve hedeflerinize ulaşmak için sizinle yakın bir iş birliği içinde çalışıyoruz"
    }
  ]

  const [isMobile, setIsMobile] = useState(false);
  const [selectedService, setSelectedService] = useState(0)
  const checkIfMobile = () => {
    const isMobile = window.innerWidth <= 768; // Mobil ekran genişliği için 768 piksel kullanıyoruz, istediğiniz değeri ayarlayabilirsiniz
    setIsMobile(isMobile);
  }

  useEffect(() => {
    setTimeout(() => {
      setSelectedService(1)
      setSelectedService(0)
      // 0. indis bug var çözülecek tablar arası geçiş olmuyor geçiçi çözüm yapıldı
    }, 10);

    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);

    return () => {
      window.removeEventListener('resize', checkIfMobile);
    };


  }, []);

  const [animationKey, setAnimationKey] = useState(0);

  useEffect(() => {
    setAnimationKey((prevKey) => prevKey + 1); // State değiştiğinde animasyonun key'ini güncelle
  }, [selectedService]);

  return (
    <div className="py-5 we-doing">
      {isMobile ?
        <div className="">
          <div className="grid grid-cols-3 grid-rows-2 gap-2">
            {data.map((val, key) => {
              return <motion.div
                onClick={() => setSelectedService(key)}
                initial={{ opacity: 0, scale: 0.2 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ delay: 0.3 }}
                viewport={{ once: true }}
                class={`mobile-wedoing mb-2 rounded-3xl flex flex-col justify-center items-center mx-2 ${key == selectedService ? 'bg-yellow-100' : 'bg-white'}`}>
                <Lottie
                  width={75}
                  height={75}
                  isClickToPauseDisabled={true}
                  options={{ animationData: val.animation }} />
                <h1 className="text-center text-base m-0">{val.title}</h1>
                {/* <div className="bg-bee-1 rounded-b-3xl p-4">
          <p>{val.content}</p>
          <div className="my-3">
            <a href="#">Read More</a>
          </div>
        </div> */}
              </motion.div>

            })}
          </div>

          <motion.div
            key={animationKey}
            initial={{ opacity: 0, }}
            animate={{ opacity: 1, }}
            transition={{ duration: 1 }}
            className="bg-white flex">
            {
              <div
                class="opacity-100 transition-opacity duration-150 ease-linear data-[te-tab-active]:block p-4"
                id={`${"questions" + selectedService}`}
                role="tabpanel"
                aria-labelledby={`${"questions" + selectedService}`}
              // {...(key == 0 ? { "data-te-tab-active": true } : {})}
              >
                {data[selectedService].content.split("\n").map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
              </div>
            }
          </motion.div>
        </div>
        :
        <Swiper
          initialSlide={2}
          effect="coverflow"
          grabCursor={true}
          centeredSlides={true}
          slidesPerView="auto"
          mousewheel="true"
          coverflowEffect={{
            rotate: 10,
            stretch: 0,
            depth: 100,
            modifier: 3,
            slideShadows: true
          }}

          pagination={{ clickable: true }}
        >
          {data.map((val) => {
            return <SwiperSlide>
              <motion.div
                initial={{ opacity: 0, }}
                whileInView={{ opacity: 1, }}
                viewport={{ once: true }}
                className="card">
                <div className="slidertext">
                  <h1>{val.title}</h1>
                  <Lottie isClickToPauseDisabled={true} options={{ animationData: val.animation }} />
                </div>
                <div className="content">
                  {val.content.split("\n").map((line, index) => (
                    <React.Fragment key={index}>
                      {line}
                      <br />
                    </React.Fragment>
                  ))}
                  {/* <a href="#">Read More</a> */}
                </div>
              </motion.div>
            </SwiperSlide>
          })}
        </Swiper>}
    </div >
  );
}

export default WeDoing;
