import React, { useState, useEffect } from 'react';

function Typewriter({ data, className }) {
    const [index, setIndex] = useState(0);
    const [subIndex, setSubIndex] = useState(0);
    const [reverse, setReverse] = useState(false);
    const text = data[index % data.length]; // Bu satır, index'i dizi uzunluğuna göre mod alarak sürekli döngü oluşturur.

    useEffect(() => {
        if (!reverse) {
            if (subIndex === text.length) {
                setTimeout(() => setReverse(true), 500);
            } else {
                setTimeout(() => setSubIndex((prev) => prev + 1), 100);
            }
        } else {
            if (subIndex === 0) {
                setReverse(false);
                // Dizinin sonuna ulaşıldığında, index'i sıfırla. Aksi takdirde, bir sonraki metne geç.
                setIndex((prevIndex) => prevIndex === data.length - 1 ? 0 : prevIndex + 1);
            } else {
                setTimeout(() => setSubIndex((prev) => prev - 1), 100);
            }
        }
    }, [subIndex, index, reverse]);

    return (
        <div className={`flex justify-center ${className && className}`}>
            <h1 className="opacity-0">-</h1>
            <h1 className="text-outline">{`${text.substring(0, subIndex)}${reverse ? "|" : ""}`}</h1>
        </div>
    );
}

export default Typewriter;
